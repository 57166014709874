// Generated by Framer (56d1180)

import { addFonts, cx, CycleVariantState, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-DUV1L"

const variantClassNames = {Ua0OIBbhV: "framer-v-12i7b78"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Ua0OIBbhV", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-12i7b78", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ua0OIBbhV"} ref={ref ?? ref1} style={{"--border-bottom-width": "0px", "--border-color": "rgb(28, 30, 32)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(19, 19, 21)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><SVG className={"framer-cla3id"} data-framer-name={"check"} fill={"rgba(0,0,0,1)"} intrinsicHeight={15} intrinsicWidth={15} layoutDependency={layoutDependency} layoutId={"XARrsn57e"} svg={"<svg width=\"15\" height=\"15\" viewBox=\"0 0 15 15\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M13.0423 4.17188L5.42253 11.7552L1.95898 8.30823\" stroke=\"#07A721\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n</svg>\n"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNTAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-font-weight": "500", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(173, 176, 183))"}}>24/7 support</motion.p></React.Fragment>} className={"framer-uorsh8"} data-framer-name={"Text"} fonts={["GF;Inter Tight-500"]} layoutDependency={layoutDependency} layoutId={"okdy0gUv0"} style={{"--extracted-r6o4lv": "rgb(173, 176, 183)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DUV1L.framer-128tizq, .framer-DUV1L .framer-128tizq { display: block; }", ".framer-DUV1L.framer-12i7b78 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: min-content; }", ".framer-DUV1L .framer-cla3id { flex: none; height: 15px; position: relative; width: 15px; }", ".framer-DUV1L .framer-uorsh8 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DUV1L.framer-12i7b78 { gap: 0px; } .framer-DUV1L.framer-12i7b78 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-DUV1L.framer-12i7b78 > :first-child { margin-left: 0px; } .framer-DUV1L.framer-12i7b78 > :last-child { margin-right: 0px; } }", ".framer-DUV1L[data-border=\"true\"]::after, .framer-DUV1L [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41.5
 * @framerIntrinsicWidth 144
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerxrYJsqL1a: React.ComponentType<Props> = withCSS(Component, css, "framer-DUV1L") as typeof Component;
export default FramerxrYJsqL1a;

FramerxrYJsqL1a.displayName = "Helper/Label BG Filled";

FramerxrYJsqL1a.defaultProps = {height: 41.5, width: 144};

addFonts(FramerxrYJsqL1a, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjPQ-qWSRToK8EPg.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})